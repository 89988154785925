







































































































































import Vue from "vue";
import Component from "vue-class-component";
import Google from "@/assets/icons/Google.svg";
import Email from "@/assets/icons/saxcons/sms-tracking-broken.svg";
import PasswordIcon from "@/assets/icons/saxcons/key-broken.svg";
import ShowIcon from "@/assets/icons/saxcons/eye-linear.svg";
import HideIcon from "@/assets/icons/saxcons/eye-slash-linear.svg";
import { Prop } from "vue-property-decorator";
import {
  createUserWithEmailAndPassword,
  getRedirectResult,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect
} from "@firebase/auth";
import { Auth, resolveUserFromAuthState } from "@/firebase";
import {
  EMAIL_VALIDATION_RULES,
  PASSWORD_VALIDATION_RULES,
  requiredFieldRule,
  ValidationRules
} from "@/utils/validation";

import { read, save } from "@/store/localStorage";
import { SportangoTextField } from "@/components/Inputs/overrides";

@Component({
  name: "login",
  components: {
    Google,
    Email,
    PasswordIcon,
    ShowIcon,
    HideIcon,
    SportangoTextField
  }
})
export default class Login extends Vue {
  @Prop({ required: false })
  isRegisterPage?: boolean;
  email = "";
  password = "";
  confirmPassword = "";
  errorMessage: string | null = null;
  emailRules: ValidationRules = [...EMAIL_VALIDATION_RULES];
  passwordRules: ValidationRules = [...PASSWORD_VALIDATION_RULES];
  confirmPasswordRules: ValidationRules = [
    ...PASSWORD_VALIDATION_RULES,
    this.isSamePassword
  ];
  isEmailSaved: boolean;
  readonly requiredFieldRule = requiredFieldRule;
  showPassword = { password: false, confirmPassword: false };
  get passwordFieldType() {
    return {
      password: this.showPassword.password ? "text" : "password",
      confirmPassword: this.showPassword.confirmPassword ? "text" : "password"
    };
  }

  get showRegisterPage(): boolean {
    return !this.$store.getters.featureFlags["direct-registration"];
  }

  get autocompleteType(): string {
    if (this.isRegisterPage) {
      return "new-password";
    } else {
      return "current-password";
    }
  }
  /**
   * This is used to determine if the form containing the user email and Phone
   * number is valid
   */
  isFormValid = false;
  constructor() {
    super();
    const savedEmail = read("userEmail");
    this.isEmailSaved = savedEmail !== null;
    if (savedEmail) {
      this.isEmailSaved = true;
      this.email = savedEmail;
    }
  }

  get heading(): string {
    if (this.isRegisterPage) {
      return "Welcome to Korolev TA";
    } else {
      return "Nice to see you again";
    }
  }
  get emailPlaceHolder(): string {
    if (this.isRegisterPage) {
      return "You will receive a link to sign up with Email directly";
    }
    return "Click on the link received to Sign back into the App";
  }

  isSamePassword(v: string): true | string {
    return v === this.password || "Passwords do not match";
  }

  async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    // Start a sign in process for an unauthenticated user.
    provider.addScope("profile");
    provider.addScope("email");
    signInWithRedirect(Auth, provider);
  }

  async signInWithEmail() {
    this.$store.commit("isLoading", true);
    if (this.isRegisterPage) {
      try {
        await createUserWithEmailAndPassword(Auth, this.email, this.password);
        const user = await resolveUserFromAuthState();
        if (user) {
          await sendEmailVerification(user);
        }
      } catch (err) {
        this.errorMessage = "Email is already in use, Please log in";
      }
    } else {
      try {
        await signInWithEmailAndPassword(Auth, this.email, this.password);
      } catch (err) {
        this.errorMessage =
          "Incorrect Password, Please enter correct password to login";
        this.$store.commit("isLoading", false);
      }
    }
    save("userEmail", this.email, "string");
  }

  async resetPassword() {
    this.$store.commit("isLoading", true);
    try {
      save("userEmail", this.email, "string");
      await sendPasswordResetEmail(Auth, this.email, {
        url: `${window.location.origin}/auth/login`,
        handleCodeInApp: true
      });
    } catch (err) {
      const error = err as Error;
      if (error.message.includes("auth/user-not-found")) {
        this.errorMessage = "No account found with this email. Please register";
        this.$store.commit("isLoading", false);
        return;
      }
      console.error(err);
    }
    this.$emit("resetSent");
    this.$store.commit("isLoading", false);
  }
  async mounted() {
    if (this.$store.getters.isLoading) {
      const redirectResult = await getRedirectResult(Auth);
      if (redirectResult === null) {
        this.$store.commit("isLoading", false);
      }
    }
  }
}
